<template>
  <div class="mt-3">
    <base-title v-model="search">Blogs</base-title>
    <v-card>
      <v-tooltip bottom open-delay="500" v-if="$can('create', 'Blog')">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark small absolute top right fab @click="createBlog" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Einen neuen Blog hinzufügen</span>
      </v-tooltip>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [10, 20, -1] }"
        :items-per-page.sync="itemsPerPage"
        virtual-rows
        sort-by="date"
        sort-desc
      >
        <template v-slot:no-data>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Blogs vorhanden</v-card-title>
            <v-card-text>Erstelle deinen ersten Blog</v-card-text>
            <v-card-actions class="d-inline-flex" v-if="$can('create', 'Blog')">
              <v-btn color="primary" @click="createBlog">Hinzufügen</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:no-results>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Blogs gefunden</v-card-title>
            <v-card-text>Mit dem Begriff "{{ search }}" wurden keine Blogs gefunden.</v-card-text>
            <v-card-actions class="d-inline-flex" v-if="$can('create', 'Blog')">
              <v-btn color="primary" @click="createBlog">Hinzufügen</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:item.date="{ item }">{{ item.date | formatDate }}</template>
        <template v-slot:item.action="{ item }">
          <div :class="$vuetify.breakpoint.xs ? 'flex flex-wrap pb-2' : 'text-no-wrap'">
            <base-mobile-button
              :icon="item.public ? 'mdi-eye' : 'mdi-eye-off'"
              :tooltip="item.public ? 'Diesen Blogbeitrag nicht mehr veröffentlichen anzeigen' : 'Diesen Blogbeitrag veröffentlichen'"
              @click="toggleStatus(item)"
              v-if="$can('publish', 'Blog')"
            >
              <span v-if="item.public">Verbergen</span>
              <span v-else>Veröffentlichen</span>
            </base-mobile-button>
            <base-mobile-button icon="mdi-pencil" tooltip="Blog bearbeiten" v-if="$can('update', 'Blog')" :to="`/blogs/${item.id}`">
              <span>Bearbeiten</span>
            </base-mobile-button>
            <base-mobile-button icon="mdi-delete" tooltip="Blog löschen" v-if="$can('delete', 'Blog')" @click="deleteBlog(item)">
              <span>Löschen</span>
            </base-mobile-button>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    search: '',
    headers: [
      { text: 'Titel', value: 'title' },
      { text: 'Autor', value: 'author.name' },
      { text: 'Veröffentlichungsdatum', value: 'date' },
      { text: 'Erstellt von', value: 'createdBy.email' },
      { value: 'action', align: 'right', sortable: false },
    ],
  }),

  computed: {
    itemsPerPage: {
      get() {
        return Number.parseInt(localStorage.getItem('table-blog-items'), 10) || 10;
      },
      set(value) {
        localStorage.setItem('table-blog-items', value);
      },
    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value.toDate()).format('DD.MM.YYYY');
      }
      return null;
    },
  },

  methods: {
    createBlog() {
      this.$store.commit('blog/dialog/form', 'meta');
    },
    deleteBlog(blog) {
      this.$store.commit('blog/dialog/delete', {
        id: blog.id,
        title: blog.title,
      });
    },
    toggleStatus(blog) {
      this.$store.dispatch('blog/status', { id: blog.id, public: !blog.public });
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table::v-deep tbody tr.v-data-table__empty-wrapper:hover {
  background: none;
}
</style>
