<template>
  <v-dialog v-model="show" max-width="600" @input="handleDialog">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <v-toolbar dark>
            <v-toolbar-title>Autor {{ hasAuthor ? "bearbeiten" : "hinzufügen" }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <base-alert v-if="error">{{ error.message }}</base-alert>
            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
              <v-text-field label="Name" v-model="author.name" :error-messages="errors" />
            </ValidationProvider>
            <ValidationProvider name="Über mich" rules="required" v-slot="{ errors }">
              <v-textarea label="Über mich" v-model="author.about" :error-messages="errors" />
            </ValidationProvider>
            <v-text-field label="Website" v-model="author.website" />
            <ValidationProvider name="Bild" rules="required" v-slot="{ errors }">
              <v-text-field label="Bild" v-model="author.image.url" :error-messages="errors" />
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">Speichern</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

const { mapFields } = createHelpers({
  getterType: 'blog/field',
  mutationType: 'blog/updateField',
});

export default {
  components: { ValidationObserver, ValidationProvider },

  computed: {
    ...mapGetters({
      dialog: 'blog/dialog/form',
      loading: 'loading/get',
      error: 'error/get',
    }),
    ...mapFields(['author']),
    show: {
      get() {
        return this.dialog === 'author';
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    hasAuthor() {
      return this.author && this.author.name && this.author.name !== '';
    },
  },

  watch: {
    dialog: {
      handler(value, oldValue) {
        if (value === false && oldValue === true) {
          this.onClose();
        }
      },
    },
  },

  methods: {
    onSubmit() {
      this.$store.dispatch('blog/update', { author: this.author });
    },
    onClose() {
      this.$store.commit('blog/dialog/form', false);
      this.$store.commit('blog/sync');
      this.$store.commit('error/set', null);
      this.$refs.form.reset();
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
  },
};
</script>
