<template>
  <fragment>
    <base-alert v-if="error">{{ error.message }}</base-alert>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <v-container>
          <v-banner sticky app class="blog-actions text-right">
            <v-btn class="mr-3" text exact to="/blogs" :disabled="loading">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">Speichern</v-btn>
          </v-banner>
          <v-row justify="center">
            <v-col cols="12" lg="10" xl="8">
              <div class="d-flex mb-5">
                <div class="text-h6 mr-3">{{ blog.date | formatDate }}</div>
                <v-tooltip bottom open-delay="500" v-if="$can('update', 'Blog')">
                  <template v-slot:activator="{ on }">
                    <v-btn text icon small @click="editMeta" v-on="on">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Meta-Informationen bearbeiten</span>
                </v-tooltip>
              </div>
              <h1 class="text-h4 mb-5">{{ blog.title }}</h1>
              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <v-card outlined :hover="$can('update', 'Blog')" @click="editAuthor" v-on="on" class="mb-5">
                    <v-card-text>
                      <v-row>
                        <v-col cols="auto">
                          <v-avatar color="grey lighten-2" size="72">
                            <cld-image :publicId="blog.author.image.url" v-if="blog.author.image && blog.author.image.url">
                              <cld-transformation gravity="face:center" height="72" width="72" crop="fill" />
                            </cld-image>
                          </v-avatar>
                        </v-col>
                        <v-col>
                          <div class="text-h6" v-if="blog.author && blog.author.name">{{ blog.author.name }}</div>
                          <v-skeleton-loader type="heading" boilerplate v-else />
                          <div class="text-body-2 mt-1" v-if="blog.author && blog.author.about">{{ blog.author.about }}</div>
                          <v-skeleton-loader type="sentences" boilerplate class="mt-2" v-else />
                          <div class="text-body-2 mt-1" v-if="blog.author && blog.author.website">
                            <a :href="blog.author.website" target="_blank">{{ blog.author.website }}</a>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </template>
                <span>Autor {{ blog.author && blog.author.name ? "bearbeiten" : "hinzufügen" }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <div class="mb-5">
            <v-img :src="headerImage" class="grey lighten-2" :aspect-ratio="1391 / 391">
              <v-row class="fill-height ma-0" align="center" justify="center" v-if="$can('update', 'Blog')">
                <v-btn @click="editHeader">Headerbild {{ headerImage ? "bearbeiten" : "hinzufügen" }}</v-btn>
              </v-row>
            </v-img>
          </div>
          <v-row justify="center">
            <v-col cols="12" lg="10" xl="8">
              <editor ref="editor" :config="config" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import dayjs from 'dayjs';
import { Cloudinary } from '@/plugins/cloudinary';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import Abstract from '@/plugins/editorjs/abstract';
import Quote from '@/plugins/editorjs/quote';
import Image from '@/plugins/editorjs/cloudinary';
import ImageText from '@/plugins/editorjs/cloudinary-text';

export default {
  components: { ValidationObserver },

  data: () => ({
    config: {
      logLevel: 'ERROR',
      placeholder: 'Beginne hier mit deinem Blogbeitrag',
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link'],
          config: {
            placeholder: 'Hier kommt deine Überschrift',
            levels: [2, 3, 4],
            defaultLevel: 2,
          },
        },
        abstract: {
          class: Abstract,
          inlineToolbar: ['link', 'italic'],
          config: {
            placeholder: 'Hier kommt deine Text',
          },
        },
        paragraph: {
          class: Paragraph,
        },
        quote: Quote,
        image: Image,
        imageText: {
          class: ImageText,
          inlineToolbar: ['link', 'bold'],
          config: {
            placeholder: 'Hier kommt deine Text',
          },
        },
      },
      data: {},
    },
  }),

  computed: {
    ...mapGetters({
      blog: 'blog/get',
      loading: 'loading/get',
      error: 'error/get',
    }),
    headerImage() {
      if (this.blog.header && this.blog.header.image && this.blog.header.image.url) {
        return Cloudinary.url(this.blog.header.image.url, {
          width: 1391, height: 391, crop: 'fill', gravity: 'face:center',
        });
      }
      return null;
    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value).format('DD.MM.YYYY');
      }
      return null;
    },
  },

  watch: {
    'blog.content': {
      handler(value) {
        if (value) {
          this.config.data = value;
        }
      },
      immediate: true,
    },
  },

  methods: {
    async onSubmit() {
      const response = await this.$refs.editor.state.editor.save().then((res) => res);
      this.$store.dispatch('blog/update', { content: response });
    },
    editMeta() {
      this.$store.commit('blog/dialog/form', 'meta');
    },
    editHeader() {
      this.$store.commit('blog/dialog/form', 'header');
    },
    editAuthor() {
      this.$store.commit('blog/dialog/form', 'author');
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.codex-editor__redactor {
  padding-bottom: 30px !important;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: 100%;
}

.theme--light.v-banner.v-sheet.blog-actions {
  background-color: #fff;
  z-index: 2 !important;
  margin-top: -$container-padding-x * 2;
}
</style>
