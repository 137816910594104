<template>
  <v-dialog v-model="show" max-width="600" @input="handleDialog">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <v-toolbar dark>
            <v-toolbar-title v-if="blog">Blog Meta-Infos bearbeiten</v-toolbar-title>
            <v-toolbar-title v-else>Blog erstellen</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <base-alert v-if="error">{{ error.message }}</base-alert>
            <ValidationProvider name="Titel" rules="required" v-slot="{ errors }">
              <v-text-field label="Titel" v-model="title" :error-messages="errors" />
            </ValidationProvider>
            <ValidationProvider name="URL Pfadsegment" rules="required" v-slot="{ errors }">
              <v-text-field label="URL Pfadsegment" v-model="slugStr" :error-messages="errors" />
            </ValidationProvider>
            <ValidationProvider name="Datum" rules="required" v-slot="{ errors }">
              <v-menu v-model="menu" :close-on-content-click="false" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFormatted" label="Datum" readonly v-bind="attrs" v-on="on" :error-messages="errors" />
                </template>
                <v-date-picker v-model="dateStr" first-day-of-week="1" no-title @input="menu = false" />
              </v-menu>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">{{ blog ? "Speichern" : "Hinzufügen" }}</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import router from '@/router';
import slugify from 'slugify';
import dayjs from 'dayjs';

const { mapFields } = createHelpers({
  getterType: 'blog/field',
  mutationType: 'blog/updateField',
});

export default {
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    menu: false,
  }),

  computed: {
    ...mapGetters({
      dialog: 'blog/dialog/form',
      blog: 'blog/get',
      loading: 'loading/get',
      error: 'error/get',
    }),
    ...mapFields(['title', 'slug', 'date', 'author', 'header']),
    show: {
      get() {
        return this.dialog === 'meta';
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isTouched() {
      return this.slug !== null;
    },
    slugStr: {
      get() {
        let { slug } = this;
        if (!this.isTouched) {
          slug = this.slugify(this.title);
        } else {
          slug = this.slugify(slug);
        }
        return slug;
      },
      set(value) {
        this.slug = value === '' ? null : value;
      },
    },
    dateStr: {
      get() {
        return dayjs(this.date).format('YYYY-MM-DD');
      },
      set(value) {
        this.date = dayjs(`${value} 00:00`).toDate();
      },
    },
    dateFormatted() {
      return dayjs(this.dateStr).format('DD.MM.YYYY');
    },
  },

  watch: {
    dialog: {
      handler(value, oldValue) {
        if (value === false && oldValue === true) {
          this.onClose();
        }
      },
    },
    date: {
      handler(value) {
        if (!value) {
          // eslint-disable-next-line newline-per-chained-call
          this.date = dayjs().hour(0).minute(0).second(0).toDate();
        }
      },
      immediate: true,
    },
  },

  methods: {
    onSubmit() {
      const data = {
        title: this.title,
        slug: this.slugStr,
        date: this.date,
        author: this.author,
        header: this.header,
      };

      if (this.blog) {
        this.$store.dispatch('blog/update', data);
      } else {
        this.$store.dispatch('blog/create', data).then((blog) => {
          router.push(`/blogs/${blog.id}`);
        });
      }
    },
    onClose() {
      this.$store.commit('blog/dialog/form', false);
      if (this.blog) {
        this.$store.commit('blog/sync');
      } else {
        this.$store.commit('blog/reset');
      }
      this.$store.commit('error/set', null);
      this.$refs.form.reset();
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
    slugify(value) {
      return slugify(value, { lower: true, locale: 'de' });
    },
  },
};
</script>
