var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600"},on:{"input":_vm.handleDialog},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-toolbar',{attrs:{"dark":""}},[(_vm.blog)?_c('v-toolbar-title',[_vm._v("Blog Meta-Infos bearbeiten")]):_c('v-toolbar-title',[_vm._v("Blog erstellen")])],1),_c('v-card-text',[(_vm.error)?_c('base-alert',[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Titel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Titel","error-messages":errors},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"URL Pfadsegment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"URL Pfadsegment","error-messages":errors},model:{value:(_vm.slugStr),callback:function ($$v) {_vm.slugStr=$$v},expression:"slugStr"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Datum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","readonly":"","error-messages":errors},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dateStr),callback:function ($$v) {_vm.dateStr=$$v},expression:"dateStr"}})],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.onClose($event)}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(_vm._s(_vm.blog ? "Speichern" : "Hinzufügen"))])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }