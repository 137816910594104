/* eslint-disable no-underscore-dangle */
import './index.css';

class Abstract {
  static get DEFAULT_PLACEHOLDER() {
    return '';
  }

  // eslint-disable-next-line object-curly-newline
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-abstract',
    };

    if (!this.readOnly) {
      this.onKeyUp = this.onKeyUp.bind(this);
    }

    this._placeholder = config.placeholder ? config.placeholder : Abstract.DEFAULT_PLACEHOLDER;
    this._data = {};
    this._element = this.drawView();
    this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

    this.data = data;
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = this._element;

    if (textContent === '') {
      this._element.innerHTML = '';
    }
  }

  drawView() {
    const div = document.createElement('div');

    div.classList.add(this._CSS.wrapper, this._CSS.block);
    div.contentEditable = false;
    div.dataset.placeholder = this.api.i18n.t(this._placeholder);

    if (!this.readOnly) {
      div.contentEditable = true;
      div.addEventListener('keyup', this.onKeyUp);
    }

    return div;
  }

  render() {
    return this._element;
  }

  merge(data) {
    const newData = {
      text: this.data.text + data.text,
    };

    this.data = newData;
  }

  validate(savedData) {
    if (savedData.text.trim() === '' && !this._preserveBlank) {
      return false;
    }

    return true;
  }

  save(toolsContent) {
    return Object.assign(this.data, {
      text: toolsContent.innerHTML,
    });
  }

  onPaste(event) {
    const data = {
      text: event.detail.data.innerHTML,
    };

    this.data = data;
  }

  static get conversionConfig() {
    return {
      export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
      import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
    };
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  get data() {
    const text = this._element.innerHTML;

    this._data.text = text;

    return this._data;
  }

  set data(data) {
    this._data = data || {};

    this._element.innerHTML = this._data.text || '';
  }

  static get pasteConfig() {
    return {
      tags: ['P'],
    };
  }

  static get toolbox() {
    return {
      icon: '<svg width="12" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.86 6.438c.475-.582.769-1.29.843-2.038A4.002 4.002 0 0 0 6.732 0H.5a.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h.996v9H.5a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 .5.5h6.541c2.213 0 4.192-1.617 4.406-3.825A4.234 4.234 0 0 0 9.86 6.437zM3.99 2.5h2.742a1.5 1.5 0 0 1 0 3H3.989v-3zm2.742 9H3.989V8h2.743a1.75 1.75 0 1 1 0 3.5z" fill="currentColor"/></svg>',
      title: 'Abstract',
    };
  }
}

export default Abstract;
