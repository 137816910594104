/* eslint-disable no-underscore-dangle */
import './index.css';

class CloudinaryImage {
  static get DEFAULT_URL_LABEL() {
    return 'Cloudinary image url';
  }

  static get DEFAULT_URL_PLACEHOLDER() {
    return 'Enter a cloudinary image url';
  }

  static get DEFAULT_CAPTION_LABEL() {
    return 'Caption';
  }

  static get DEFAULT_CAPTION_PLACEHOLDER() {
    return 'Enter a caption';
  }

  // eslint-disable-next-line object-curly-newline
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-cloudninary',
      input: this.api.styles.input,
      label: 'ce-cloudninary__label',
      url: 'ce-cloudninary__url',
      caption: 'ce-cloudninary__caption',
    };

    this._urlLabel = config.url && config.url.label ? config.url.label : CloudinaryImage.DEFAULT_URL_LABEL;
    this._urlPlaceholder = config.url && config.url.placeholder ? config.url.placeholder : CloudinaryImage.DEFAULT_URL_PLACEHOLDER;
    this._captionLabel = config.caption && config.caption.label ? config.caption.label : CloudinaryImage.DEFAULT_CAPTION_LABEL;
    this._captionPlaceholder = config.caption && config.caption.placeholder ? config.caption.placeholder : CloudinaryImage.DEFAULT_CAPTION_PLACEHOLDER;

    this._data = {};
    this._element = this.drawView();

    this.data = data;
  }

  drawView() {
    const container = document.createElement('div');
    container.classList.add(this._CSS.wrapper, this._CSS.block);

    const urlContainer = document.createElement('div');

    const urlLabel = document.createElement('div');
    urlLabel.classList.add(this._CSS.label);
    urlLabel.innerHTML = this.api.i18n.t(this._urlLabel);

    const urlInput = document.createElement('input');
    urlInput.classList.add(this._CSS.input, this._CSS.url);
    urlInput.contentEditable = !this.readOnly;
    urlInput.placeholder = this.api.i18n.t(this._urlPlaceholder);

    urlContainer.appendChild(urlLabel);
    urlContainer.appendChild(urlInput);

    const captionContainer = document.createElement('div');

    const captionLabel = document.createElement('div');
    captionLabel.classList.add(this._CSS.label);
    captionLabel.innerHTML = this.api.i18n.t(this._captionLabel);

    const captionInput = document.createElement('input');
    captionInput.classList.add(this._CSS.input, this._CSS.caption);
    captionInput.contentEditable = !this.readOnly;
    captionInput.placeholder = this.api.i18n.t(this._captionPlaceholder);

    captionContainer.appendChild(captionLabel);
    captionContainer.appendChild(captionInput);

    container.appendChild(urlContainer);
    container.appendChild(captionContainer);

    return container;
  }

  render() {
    return this._element;
  }

  // eslint-disable-next-line class-methods-use-this
  validate(savedData) {
    return savedData.url !== '';
  }

  save(toolsContent) {
    const url = toolsContent.querySelector(`.${this._CSS.url}`);
    const caption = toolsContent.querySelector(`.${this._CSS.caption}`);

    return Object.assign(this.data, {
      url: url.value,
      caption: caption.value,
    });
  }

  static get sanitize() {
    return {
      url: false,
      caption: false,
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  get data() {
    const url = this._element.querySelector(`.${this._CSS.url}`).value;
    const caption = this._element.querySelector(`.${this._CSS.caption}`).value;

    this._data.url = url;
    this._data.caption = caption;

    return this._data;
  }

  set data(data) {
    this._data = data || {};

    this._element.querySelector(`.${this._CSS.url}`).value = this._data.url || '';
    this._element.querySelector(`.${this._CSS.caption}`).value = this._data.caption || '';
  }

  static get toolbox() {
    return {
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
      title: 'Image',
    };
  }
}

export default CloudinaryImage;
