var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600"},on:{"input":_vm.handleDialog},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Autor "+_vm._s(_vm.hasAuthor ? "bearbeiten" : "hinzufügen"))])],1),_c('v-card-text',[(_vm.error)?_c('base-alert',[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors},model:{value:(_vm.author.name),callback:function ($$v) {_vm.$set(_vm.author, "name", $$v)},expression:"author.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Über mich","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Über mich","error-messages":errors},model:{value:(_vm.author.about),callback:function ($$v) {_vm.$set(_vm.author, "about", $$v)},expression:"author.about"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Website"},model:{value:(_vm.author.website),callback:function ($$v) {_vm.$set(_vm.author, "website", $$v)},expression:"author.website"}}),_c('ValidationProvider',{attrs:{"name":"Bild","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Bild","error-messages":errors},model:{value:(_vm.author.image.url),callback:function ($$v) {_vm.$set(_vm.author.image, "url", $$v)},expression:"author.image.url"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.onClose($event)}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v("Speichern")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }