/* eslint-disable no-underscore-dangle */
import './index.css';

class CloudinaryText {
  static get DEFAULT_URL_LABEL() {
    return 'Cloudinary image puplic id';
  }

  static get DEFAULT_URL_PLACEHOLDER() {
    return 'Enter cloudinary image puplic id';
  }

  static get DEFAULT_CAPTION_LABEL() {
    return 'Caption';
  }

  static get DEFAULT_CAPTION_PLACEHOLDER() {
    return 'Enter a caption';
  }

  static get DEFAULT_PLACEHOLDER() {
    return '';
  }

  // eslint-disable-next-line object-curly-newline
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-cloudninary-text',
      input: this.api.styles.input,
      label: 'ce-cloudninary-text__label',
      url: 'ce-cloudninary-text__url',
      caption: 'ce-cloudninary-text__caption',
      text: 'ce-cloudninary-text__text',
    };

    this._urlLabel = config.url && config.url.label ? config.url.label : CloudinaryText.DEFAULT_URL_LABEL;
    this._urlPlaceholder = config.url && config.url.placeholder ? config.url.placeholder : CloudinaryText.DEFAULT_URL_PLACEHOLDER;
    this._captionLabel = config.caption && config.caption.label ? config.caption.label : CloudinaryText.DEFAULT_CAPTION_LABEL;
    this._captionPlaceholder = config.caption && config.caption.placeholder ? config.caption.placeholder : CloudinaryText.DEFAULT_CAPTION_PLACEHOLDER;
    this._placeholder = config.placeholder ? config.placeholder : CloudinaryText.DEFAULT_PLACEHOLDER;

    this._data = {};
    this._element = this.drawView();

    this.data = data;
  }

  drawView() {
    const container = document.createElement('div');
    container.classList.add(this._CSS.wrapper, this._CSS.block);

    const imageContainer = document.createElement('div');

    const urlContainer = document.createElement('div');

    const urlLabel = document.createElement('div');
    urlLabel.classList.add(this._CSS.label);
    urlLabel.innerHTML = this.api.i18n.t(this._urlLabel);

    const urlInput = document.createElement('input');
    urlInput.classList.add(this._CSS.input, this._CSS.url);
    urlInput.contentEditable = !this.readOnly;
    urlInput.placeholder = this.api.i18n.t(this._urlPlaceholder);

    urlContainer.appendChild(urlLabel);
    urlContainer.appendChild(urlInput);

    const captionContainer = document.createElement('div');

    const captionLabel = document.createElement('div');
    captionLabel.classList.add(this._CSS.label);
    captionLabel.innerHTML = this.api.i18n.t(this._captionLabel);

    const captionInput = document.createElement('input');
    captionInput.classList.add(this._CSS.input, this._CSS.caption);
    captionInput.contentEditable = !this.readOnly;
    captionInput.placeholder = this.api.i18n.t(this._captionPlaceholder);

    captionContainer.appendChild(captionLabel);
    captionContainer.appendChild(captionInput);

    imageContainer.appendChild(urlContainer);
    imageContainer.appendChild(captionContainer);

    const textContainer = document.createElement('div');

    const text = document.createElement('div');

    text.classList.add(this._CSS.text);
    text.contentEditable = false;
    text.dataset.placeholder = this.api.i18n.t(this._placeholder);

    if (!this.readOnly) {
      text.contentEditable = true;
    }

    textContainer.appendChild(text);

    container.appendChild(imageContainer);
    container.appendChild(textContainer);

    return container;
  }

  render() {
    return this._element;
  }

  // eslint-disable-next-line class-methods-use-this
  validate(savedData) {
    return savedData.url !== '';
  }

  save(toolsContent) {
    const url = toolsContent.querySelector(`.${this._CSS.url}`);
    const caption = toolsContent.querySelector(`.${this._CSS.caption}`);
    const text = toolsContent.querySelector(`.${this._CSS.text}`);

    return Object.assign(this.data, {
      url: url.value,
      caption: caption.value,
      text: text.innerHTML,
    });
  }

  static get conversionConfig() {
    return {
      export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
      import: 'text', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
    };
  }

  static get sanitize() {
    return {
      url: false,
      caption: false,
      text: {
        br: true,
      },
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  get data() {
    const url = this._element.querySelector(`.${this._CSS.url}`).value;
    const caption = this._element.querySelector(`.${this._CSS.caption}`).value;
    const text = this._element.querySelector(`.${this._CSS.text}`).innerHTML;

    this._data.url = url;
    this._data.caption = caption;
    this._data.text = text;

    return this._data;
  }

  set data(data) {
    this._data = data || {};

    this._element.querySelector(`.${this._CSS.url}`).value = this._data.url || '';
    this._element.querySelector(`.${this._CSS.caption}`).value = this._data.caption || '';
    this._element.querySelector(`.${this._CSS.text}`).innerHTML = this._data.text || '';
  }

  static get toolbox() {
    return {
      icon: '<svg width="16" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 0h-13A1.5 1.5 0 000 1.5v11A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0014.5 0zM7 12H2V4h5v8zm7 0H9V4h5v8z" fill="currentColor"/></svg>',
      title: 'Image Text',
    };
  }
}

export default CloudinaryText;
