<template>
  <div>
    <Detail v-if="!loadingData" />
    <base-loading v-else />
    <Meta />
    <Header />
    <Author />
  </div>
</template>

<script>
export default {
  components: {
    Detail: () => import(/* webpackChunkName: "blog" */ '@/components/scoped/blog/Detail.vue'),
    Meta: () => import(/* webpackChunkName: "blog" */ '@/components/scoped/blog/Meta.vue'),
    Header: () => import(/* webpackChunkName: "blog" */ '@/components/scoped/blog/Header.vue'),
    Author: () => import(/* webpackChunkName: "blog" */ '@/components/scoped/blog/Author.vue'),
  },

  data: () => ({
    loadingData: true,
  }),

  watch: {
    '$route.params.blogID': {
      handler(value) {
        if (!value) {
          this.$store.commit('blog/reset');
        }
      },
    },
  },

  methods: {
    loadData() {
      if (this.$route.params.blogID) {
        this.$store.dispatch('blog/load', this.$route.params.blogID).then((blog) => {
          if (blog) {
            this.$store.commit('blog/sync');
            this.$data.loadingData = false;
          }
        });
      }
    },
    destroyData() {
      this.$store.commit('blog/reset');
      this.$data.loadingData = true;
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
