<template>
  <fragment>
    <Blog :data="blogs" :loading="loading" />
    <Meta />
    <Delete />
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    Blog: () => import(/* webpackChunkName: "blog" */ '@/components/scoped/blog/Table.vue'),
    Meta: () => import(/* webpackChunkName: "blog" */ '@/components/scoped/blog/Meta.vue'),
    Delete: () => import(/* webpackChunkName: "blog" */ '@/components/scoped/blog/Delete.vue'),
  },

  computed: {
    ...mapGetters({
      loading: 'blog/loading',
      blogs: 'blog/all',
    }),
  },

  methods: {
    loadData() {
      this.$store.dispatch('blog/loadAll');
    },
    destroyData() {
      this.$store.commit('blog/resetAll');
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
